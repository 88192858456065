import SiteButton_ButtonLiftedShadowComponent from '@wix/thunderbolt-elements/src/components/SiteButton/viewer/skinComps/ButtonLiftedShadow/ButtonLiftedShadow.skin';


const SiteButton_ButtonLiftedShadow = {
  component: SiteButton_ButtonLiftedShadowComponent
};


export const components = {
  ['SiteButton_ButtonLiftedShadow']: SiteButton_ButtonLiftedShadow
};

